<template>
  <company-component />
</template>

<script>
import CompanyComponent from "@/components/admin/CompanyComponent";

export default {
  name: "Company",
  title: "Datos de la Organización | Baja California Health Tourism",
  components: {
    CompanyComponent,
  },
};
</script>

<style scoped></style>
